.bitter-bar {
  cursor: pointer;
  position: relative;
}

.bitter-bar:hover::before {
  display: block;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: " ";
}

.bitter-bar:hover .tooltip {
  display: block;
}

.bitter-bar .tooltip {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 12px);
  font-family: Lufga;
  font-weight: 700;
  color: white;
  background: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  line-height: 12px;
  padding: 4px 6px;
  border-radius: 4px;
  z-index: 2;
}

.bitter-bar .tooltip::after {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
}

.bitter-bar .tooltip span {
  margin-left: 6px;
  margin-right: 6px;
}
